import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CreateEditConversationPhase from '../../../components/conversation-phase/CreateEdit.jsx';

export default function CreateEditConversationType() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [conversationType, setConversationType] = useState(null);
  const [createPhaseVisible, setCreatePhaseVisible] = useState(false);
  const [phases, setPhases] = useState([]);

  async function getConversationType() {
    try {
      const { data } = await axios.get(`/api/conversation-type/${id}`);
      setConversationType(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function getConversationPhases() {
    try {
      const { data } = await axios.get(
        `/api/admin/conversation-phase/${id}/list`,
      );
      setPhases(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!id) return;
    getConversationType();
    getConversationPhases();
  }, [id]);

  async function save() {
    try {
      const response = await axios.post(
        '/api/admin/conversation-type/create-edit',
        conversationType,
      );
      if (!id) {
        navigate(
          `/admin/conversation-type/${response.data.conversationType.id}/edit`,
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h1 className="card-title">Create/Edit Conversation Type</h1>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="prompt" className="form-label">
              Prompt:
            </label>
            <textarea
              id="prompt"
              className="form-control"
              value={conversationType?.rolePrompt}
              onChange={(e) =>
                setConversationType({
                  ...conversationType,
                  rolePrompt: e.target.value,
                })
              }
              rows="15"
            />
          </div>
          <button type="submit" className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </div>
      {id && (
        <div className="card">
          <div className="card-header">
            <h1 className="card-title">Conversation Phases</h1>
          </div>
          <div className="card-body">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th className="col-1">Index</th>
                  <th>Role Prompt</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {phases.map((phases) => (
                  <CreateEditConversationPhase
                    key={phases.id}
                    conversationType={conversationType}
                    conversationPhase={phases}
                  />
                ))}
                {!createPhaseVisible && (
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={() => setCreatePhaseVisible(true)}
                  >
                    Create Conversation Phase
                  </button>
                )}
                {createPhaseVisible && (
                  <CreateEditConversationPhase
                    conversationType={conversationType}
                    conversationPhase={{
                      index: phases[phases.length - 1]?.index + 1 || 0,
                      prompt: '',
                    }}
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
