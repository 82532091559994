import React from 'react';

function CustomInput({
  label,
  id,
  type,
  value,
  onChange,
  className = '',
  required = false,
}) {
  return (
    <div className={`form-group mt-3 ${className}`}>
      {label && <label htmlFor={id}>{label}:</label>}
      <input
        id={id}
        type={type}
        className="form-control"
        value={value}
        onChange={onChange}
        {...(required ? { required: true } : {})}
      />
    </div>
  );
}

export default CustomInput;
