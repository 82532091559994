import React from 'react';

function CustomColumnCard({ title, data }) {
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text fs-2 fw-bold">{data}</p>
        </div>
      </div>
    </div>
  );
}

export default CustomColumnCard;
