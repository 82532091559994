import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import './dropzone.css';

function Dropzone({ onDrop, onError }) {
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    [onDrop],
  );

  const onDropRejected = useCallback(
    (fileRejections) => {
      const error =
        fileRejections[0]?.errors[0]?.message || 'File upload failed';
      onError(error);
    },
    [onError],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: false,
    maxSize: 500 * 1024 * 1024, // 500 MB
    accept: {
      'audio/wav': ['.wav'],
      'audio/x-wav': ['.wav'],
      'audio/mpeg': ['.mp3'],
      'audio/ogg': ['.ogg'],
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
      'video/x-matroska': ['.mkv'],
    },
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        <p>
          Drag &apos;n&apos; drop an audio or video file here, or click to
          select one
        </p>
      )}
      <p className="file-info">
        Accepted formats: .wav, .mp3, .ogg, .mp4, .mov, .avi, .mkv (max 500MB)
      </p>
    </div>
  );
}

export default Dropzone;
