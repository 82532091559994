function getErrorMessage(error) {
  const message = error.response?.data;

  let firstKeyMessage;

  if (message && typeof message === 'object' && !Array.isArray(message)) {
    const firstKey = Object.keys(message)[0];
    firstKeyMessage = message[firstKey];
  }

  return (
    message?.error ||
    message?.message ||
    firstKeyMessage ||
    (typeof message === 'string' &&
    !message.trim().toLowerCase().startsWith('<!doctype html>')
      ? message
      : null) ||
    'An unexpected error occurred.'
  );
}

export default getErrorMessage;
