import axios from 'axios';
import React, { useEffect, useState } from 'react';

import CustomColumnCard from '../../components/utils/CustomColumnCard.jsx';
import ErrorCard from '../../components/utils/ErrorCard.jsx';
import Loader from '../../components/utils/Loader.jsx';
import getErrorMessage from '../../utils/getErrorMessage.js';

// Hardcoded data (replace this with your Axios call later)
const salesData = {
  agentMessagesSent: 1250,
  agentConversationsHad: 450,
  agentClosedCalls: 75,
  totalSales: 150000,
  averageOrderValue: 4000,
  conversionRate: 16.67,
  topSellingProduct: 'Mentorship program',
  customerSatisfactionScore: 4.7,
};

function StatisticsDashboard() {
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getStatistics() {
      try {
        const { data } = await axios.get('/api/conversation/statistics');
        setStatistics(data);
        console.log(statistics);
      } catch (err) {
        setError(getErrorMessage(err));
      } finally {
        setLoading(false);
      }
    }
    getStatistics();
  }, []);

  if (loading) return <Loader />;

  if (error) return <ErrorCard error={error} />;

  const totalSalesStr =
    '$' + statistics.numClosedConversations * salesData.averageOrderValue;
  const cardsData = [
    { index: '1', title: 'Total messages', data: statistics.numMessages },
    { index: '2', title: 'Total calls', data: statistics.numConversations },
    {
      index: '3',
      title: 'Closed Calls',
      data: statistics.numClosedConversations,
    },
    {
      index: '4',
      title: 'Total Opportunity Value',
      data: totalSalesStr.toLocaleString(),
    },
    {
      index: '5',
      title: 'Average Opportunity Value',
      data: '$' + salesData.averageOrderValue?.toLocaleString(),
    },
    {
      index: '6',
      title: 'Conversion Rate',
      data:
        parseFloat(
          (statistics.numClosedConversations * 100) /
            statistics.numConversations,
        ).toFixed(2) + '%',
    },
    {
      index: '7',
      title: 'Top Selling Product',
      data: salesData.topSellingProduct,
    },
    {
      index: '8',
      title: 'Average Call Rating',
      data: parseFloat(statistics.averageRating).toFixed(2),
    },
  ];

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Sales Statistics Dashboard</h1>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
        {cardsData.map((card) => (
          <CustomColumnCard
            key={card.index}
            title={card.title}
            data={card.data}
          />
        ))}
      </div>
    </div>
  );
}

export default StatisticsDashboard;
