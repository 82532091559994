import StarRating from './StarRating/StarRating.jsx';
import CustomCard from './utils/CustomCard.jsx';
import CustomList from './utils/CustomList.jsx';
import ShareButtons from './utils/ShareButtons.jsx';

export default function ViewEvaluation({ evaluation, shareUrl, shareTitle }) {
  return (
    <CustomCard title="Evaluation Results" width={10}>
      <div className="mb-4 text-center">
        <StarRating rating={evaluation.grade} />
      </div>
      <div className="row g-4">
        <div className="col-lg-6">
          <h3 className="fs-4 mb-3 d-flex align-items-center">
            <i className="bi bi-hand-thumbs-up-fill text-success me-2 fs-3"></i>
            Positive Points
          </h3>
          <CustomList items={evaluation.positive} numberColor="bg-success" />
        </div>
        <div className="col-lg-6">
          <h3 className="fs-4 mb-3 d-flex align-items-center">
            <i
              className="bi bi-hand-thumbs-down-fill
                text-danger me-2 fs-3"
            ></i>
            Areas for Improvement
          </h3>
          <CustomList items={evaluation.negative} numberColor="bg-danger" />
        </div>
      </div>
      <div className="mt-4 w-100">
        <h3 className="fs-4 mb-3 d-flex align-items-center">
          <i className="bi bi-question-circle-fill text-info me-2 fs-3"></i>
          Suggested Questions/Statements
        </h3>
        <CustomList
          items={evaluation.example_questions_statements}
          numberColor="bg-info"
          itemBackground="list-group-item-info"
        />
      </div>
      {shareUrl && <ShareButtons url={shareUrl} title={shareTitle} />}
    </CustomCard>
  );
}
