import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConversationTypeSelector from '../components/conversation/ConversationTypeSelector.jsx';

export default function Call() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  async function callNumber() {
    if (!selectedTypeId) {
      setError('Please select a conversation type.');
      return;
    }
    console.log(
      `Calling ${phoneNumber} with conversation type ${selectedTypeId}`,
    );
    try {
      const { data } = await axios.post(`/api/call/${phoneNumber}`, {
        conversationTypeId: selectedTypeId,
      });
      navigate(`/conversation/${data.uuid}?viewOnly=true`);
    } catch (error) {
      console.error('Error making call:', error);
      setError('Failed to make the call. Please try again.');
    }
  }

  return (
    <div
      className="container d-flex justify-content-center
        align-items-center min-vh-100"
    >
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-center mb-4">Make a Call</h5>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
            />
          </div>
          <div className="mb-3">
            <ConversationTypeSelector onSelect={setSelectedTypeId} />
          </div>
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={callNumber}
            disabled={!phoneNumber || !selectedTypeId}
          >
            Call
          </button>
          {error && <div className="text-danger mt-2">{error}</div>}
        </div>
      </div>
    </div>
  );
}
