import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { truncateText } from '../../utils/string.js';

const MAX_TEXT_LENGTH = 70;

export default function ConversationTypeSelector({ onSelect }) {
  const [conversationTypes, setConversationTypes] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState('');

  async function getConversationTypes() {
    try {
      const { data } = await axios.get('/api/conversation-type/listt');
      setConversationTypes(data);
      if (data.length > 0) {
        setSelectedTypeId(data[0].id);
        onSelect(data[0].id);
      }
    } catch (error) {
      console.error('Error fetching conversation types:', error);
    }
  }

  useEffect(() => {
    getConversationTypes();
  }, [onSelect]);

  function handleSelect(event) {
    const value = event.target.value;
    setSelectedTypeId(value);
    onSelect(value);
  }

  return (
    <select
      className="form-select"
      value={selectedTypeId}
      onChange={handleSelect}
      aria-label="Select conversation type"
    >
      {conversationTypes.map((type) => (
        <option key={type.id} value={type.id}>
          {truncateText(type.rolePrompt, MAX_TEXT_LENGTH)}
        </option>
      ))}
    </select>
  );
}
