import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

export function ShareButtons({ title, url }) {
  return (
    <div className="d-flex justify-content-center gap-3 my-3">
      <FacebookShareButton url={url} className="btn btn-outline-primary">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        className="btn btn-outline-info"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <LinkedinShareButton url={url} className="btn btn-outline-primary">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <TelegramShareButton
        url={url}
        title={title}
        className="btn btn-outline-info"
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <WhatsappShareButton
        url={url}
        title={title}
        separator=": "
        className="btn btn-outline-success"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
}

export default ShareButtons;
