import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function ProtectedRoute({ role }) {
  const userRole = useSelector((state) => state.auth.role);
  const location = useLocation();

  if (role && userRole !== 'ADMIN') {
    return <Navigate to="/login" state={location.pathname} />;
  }

  return <Outlet />;
}
