import React from 'react';

function Footer() {
  return (
    <footer className="__footer bg-light text-center">
      <div className="container">
        <span className="text-muted">© 2024 SCWell. All rights reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
