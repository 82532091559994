import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Layout from './components/layouts/Layout/Layout.jsx';
import Loader from './components/utils/Loader.jsx';
import { login, logout } from './features/auth.js';
import Call from './routes/Call.jsx';
import Evaluation from './routes/Evaluation.jsx';
import ProtectedRoute from './routes/ProtectedRoute.jsx';
import UploadFile from './routes/UploadFile.jsx';
import Hello from './routes/admin/Hello.jsx';
import Login from './routes/admin/Login.jsx';
import CreateEditConversationType from './routes/admin/conversation-type/CreateEdit.jsx';
import ConversationTypesList from './routes/admin/conversation-type/List.jsx';
import NewConversation from './routes/conversation/New.jsx';
import CallPerformance from './routes/conversation/Performance.jsx';
import StatisticsDashboard from './routes/conversation/StatisticsDashboard.jsx';
import ViewConversation from './routes/conversation/View.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <UploadFile />,
      },
      {
        path: '/call',
        element: <Call />,
      },
      {
        path: '/evaluation/:uuid',
        element: <Evaluation />,
      },
      {
        path: '/conversation/new',
        element: <NewConversation />,
      },
      {
        path: '/conversation/:uuid',
        element: <ViewConversation />,
      },
      {
        path: '/conversation/:uuid/performance',
        element: <CallPerformance />,
      },
      {
        path: '/conversation/statistics',
        element: <StatisticsDashboard />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/admin',
        element: <ProtectedRoute role="ADMIN" />,
        children: [
          {
            path: 'hello',
            element: <Hello />,
          },
          {
            path: 'conversation-type/create',
            element: <CreateEditConversationType />,
          },
          {
            path: 'conversation-type/:id/edit',
            element: <CreateEditConversationType />,
          },
          {
            path: 'conversation-type/list',
            element: <ConversationTypesList />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  async function setUserSession() {
    try {
      const { data, status } = await axios.post('/api/user/get-session');
      if (status === 200 && data.session.email) {
        dispatch(login({ email: data.session.email, role: data.session.role }));
      }
    } catch (error) {
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setUserSession();
  }, []);

  if (loading) return <Loader />;

  return <RouterProvider router={router} />;
}

export default App;
