import React from 'react';

function Loader({ message = 'Loading...' }) {
  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex
        justify-content-center align-items-center"
      style={{
        zIndex: 9999,
      }}
    >
      <div className="text-center">
        <div
          className="spinner-border text-dark mb-3"
          role="status"
          style={{
            width: '3.3rem',
            height: '3.3rem',
            borderWidth: '0.4rem',
          }}
        >
          <span className="visually-hidden">{message}</span>
        </div>
        <div className="text-dark fs-5">{message}</div>
      </div>
    </div>
  );
}

export default Loader;
