import React from 'react';

function ErrorCard({ error }) {
  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-4 alert alert-danger text-center mt-5">
        {error}
      </div>
    </div>
  );
}

export default ErrorCard;
