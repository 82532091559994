import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ViewEvaluation from '../components/ViewEvaluation.jsx';
import ErrorCard from '../components/utils/ErrorCard.jsx';
import Loader from '../components/utils/Loader.jsx';

function Evaluation() {
  const { uuid } = useParams();
  const [evaluation, setEvaluation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchEvaluation() {
      try {
        const response = await axios.get(`/api/evaluation/${uuid}/`);
        if (response.status !== 202) {
          const data = JSON.parse(response.data.review);
          setEvaluation(data);
        } else if (response.status === 202) {
          setTimeout(fetchEvaluation, 10000);
        }
      } catch (err) {
        setError('Failed to fetch evaluation');
      }
    }

    fetchEvaluation();
  }, [uuid]);

  if (error) return <ErrorCard error={error} />;
  if (!evaluation) return <Loader message="Evaluating..." />;

  // Generate the dynamic share URL and title
  const shareUrl = `${window.location.origin}/evaluation/${uuid}`;
  const shareTitle = `Hey, check out my grade: ${evaluation.grade}!`;

  return (
    <ViewEvaluation
      evaluation={evaluation}
      shareUrl={shareUrl}
      shareTitle={shareTitle}
    />
  );
}

export default Evaluation;
