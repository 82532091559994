import React from 'react';

import './starRating.css';

function StarRating({ rating, maximumStars = 5 }) {
  return (
    <div className="star-rating">
      {[...Array(maximumStars)].map((star, index) => {
        index += 1;
        return (
          <span key={index} className={index <= rating ? 'on' : 'off'}>
            &#9733;
          </span>
        );
      })}
    </div>
  );
}

export default StarRating;
