import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomCard from '../../components/utils/CustomCard.jsx';
import CustomInput from '../../components/utils/CustomInput.jsx';
import ResponseMessage from '../../components/utils/ResponseMessage.jsx';
import { login } from '../../features/auth.js';
import getErrorMessage from '../../utils/getErrorMessage.js';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, [email, password]);

  function validateForm() {
    if (!email) {
      setError('Email is required');
      return false;
    }
    if (!password) {
      setError('Password is required');
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const { status, data } = await axios.post('/api/user/login', {
        email,
        password,
      });
      if (status === 200 && data.session.email) {
        dispatch(login({ email: data.session.email, role: data.session.role }));
        navigate('/admin/hello', { replace: true });
      }
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={3}>
      <ResponseMessage message={error} type="error" />
      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <CustomInput
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary mt-3 mb-4 w-100"
        >
          Log In
        </button>
      </form>
    </CustomCard>
  );
}

export default Login;
