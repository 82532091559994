import React from 'react';

function CustomList({
  items,
  itemBackground = '',
  numberColor = 'bg-primary',
}) {
  const itemClass = `list-group-item ${itemBackground}`;
  const numberClass = `badge ${numberColor} rounded-pill me-2`;

  return (
    <ul className="list-group">
      {items.map((question, index) => (
        <li key={index} className={itemClass}>
          <div className="d-flex align-items-start">
            <span className={numberClass}>{index + 1}</span>
            <span>{question}</span>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default CustomList;
