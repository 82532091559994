import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const MAX_TEXT_LENGTH = 200;

export default function ConversationTypesList() {
  const [conversationTypes, setConversationTypes] = useState([]);

  async function fetchConversationTypes() {
    try {
      const response = await axios.get('/api/conversation-type/listt');
      setConversationTypes(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchConversationTypes();
  }, []);

  async function deleteConversationType(id) {
    try {
      await axios.post(`/api/admin/conversation-type/${id}/delete`);
      setConversationTypes(
        conversationTypes.filter(
          (conversationType) => conversationType.id !== id,
        ),
      );
    } catch (error) {
      console.error(error);
    }
  }

  function truncateText(text, maxLength) {
    return text.length > maxLength ? `${text.substr(0, maxLength)}...` : text;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Conversation Types</h1>
      <Link
        to="/admin/conversation-type/create"
        className="btn btn-primary mb-3"
      >
        Create Conversation Type
      </Link>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Prompt</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {conversationTypes.map((conversationType) => (
            <tr key={conversationType.id}>
              <td>{conversationType.id}</td>
              <td>
                {truncateText(conversationType.rolePrompt, MAX_TEXT_LENGTH)}
              </td>
              <td>
                <div className="btn-group" role="group">
                  <Link
                    to={`/admin/conversation-type/${conversationType.id}/edit`}
                    className="btn btn-warning btn-sm"
                  >
                    Edit
                  </Link>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteConversationType(conversationType.id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
