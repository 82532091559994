import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ViewEvaluation from '../../components/ViewEvaluation.jsx';

export default function CallPerformance() {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [performance, setPerformance] = useState(null);

  useEffect(() => {
    async function fetchPerformance() {
      try {
        const response = await axios.get(
          `/api/conversation/${uuid}/performance`,
        );
        if (!performance) {
          setPerformance(JSON.parse(response.data));
        }
        console.log(performance);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    if (!performance) fetchPerformance();
  }, [uuid]);

  if (loading) {
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error loading performance: {error.message}</p>;
  }

  return <ViewEvaluation evaluation={performance} />;
}
