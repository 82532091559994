import React from 'react';

function ResponseMessage({ message, type }) {
  if (!message) return null;

  const messageClass = type === 'error' ? 'text-danger' : 'text-success';

  return <p className={`${messageClass} mt-2`}>{message}</p>;
}

export default ResponseMessage;
