import React, { useEffect, useState } from 'react';

import './globalMessage.css';

function GlobalMessage({ message, onClose }) {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const lastShownTime = localStorage.getItem('globalMessageLastShown');
    const currentTime = new Date().getTime();
    const threeHoursInMs = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

    // If the last shown time is more than 3 hours ago, show the message
    if (
      !lastShownTime ||
      currentTime - parseInt(lastShownTime) > threeHoursInMs
    ) {
      setShowMessage(true);
      localStorage.setItem('globalMessageLastShown', currentTime.toString());
    }

    if (showMessage) {
      const timer = setTimeout(() => {
        handleClose();
      }, 10000); // Auto close after 10 seconds

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const handleClose = () => {
    setShowMessage(false);
    onClose();
  };

  if (!showMessage) return null;

  return (
    <div className="message-container">
      <div
        className="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <strong className="me-auto">Notification</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="toast-body">{message}</div>
      </div>
    </div>
  );
}

export default GlobalMessage;
