import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../Footer.jsx';
import GlobalMessage from '../GlobalMessage/GlobalMessage.jsx';
import Navbar from '../Navbar.jsx';
import './layout.css';

function Layout() {
  const [showMessage, setShowMessage] = useState(true);
  const globalMessage = process.env.REACT_APP_GLOBAL_MESSAGE;

  return (
    <>
      {showMessage && globalMessage && (
        <GlobalMessage
          message={globalMessage}
          onClose={() => setShowMessage(false)}
        />
      )}
      <div className="d-flex flex-column min-vh-100">
        <Navbar />
        <main className="flex-grow-1">
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
