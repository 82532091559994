import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { logout } from '../../features/auth';

function Navbar() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await axios.post('/api/user/logout');
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      // ignored
    }
  }

  const baseButtonClass = 'nav-link rounded px-2 py-1 bg-transparent';

  return (
    <nav
      className="__header navbar navbar-expand-lg navbar-light
        bg-light shadow-sm"
    >
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          SCWell
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" exact>
                Evaluate call
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/conversation/new">
                Sales Agent
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/conversation/statistics">
                Statistics
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              {isAuthenticated ? (
                <button
                  type="button"
                  className={`${baseButtonClass}
                    text-secondary border border-secondary`}
                  onClick={handleLogout}
                >
                  Logout
                </button>
              ) : (
                <NavLink
                  className={({ isActive }) =>
                    `${baseButtonClass} ${
                      isActive
                        ? 'text-dark border border-dark'
                        : 'text-secondary border border-secondary'
                    }`
                  }
                  to="/login"
                >
                  Login
                </NavLink>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
